
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import { ListDataSource } from "@/data/List/ListDataSource";
import FileUploaderDialog from "@/components/helpers/file-uploader-dialog.vue";

import axios from "axios";
@Component({
  components: { FileUploaderDialog },
})
export default class Home extends Vue {
  audioTextDialogVisible: boolean = false;
  currentItem: any = null;
  currentTime: number = 0;
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "AudiofileProject",
  });
  audioDataSource: ListDataSource = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 100,
      filter: JSON.stringify([
        { fieldName: "projectId", fieldValue: parseInt(this.$route.params.id) },
      ]),
    },
    className: "audiofile",
    beforeAdd: (model) => {
      model.projectId = this.$route.params.id;
    },
  });

  headers: Array<any> = [
    {
      text: "ID",
      value: "id",
      sortable: true,
    },
    {
      text: "Дата создания",
      value: "createDate",
      sortable: true,
    },
    {
      text: "Плеер",
      value: "player",
      align: "end",
      sortable: false,
    },
    {
      text: "Имя файла",
      value: "name",
      sortable: true,
    },
    {
      text: "Размер файла",
      value: "fileSize",
      sortable: true,
    },
    {
      text: "Стенограмма",
      value: "transcribtion",
      sortable: false,
    },
    {
      text: "Дата изменения стенограмы",
      value: "transcribtionDate",
      sortable: true,
    },
    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  uploaderDialogVisible: boolean = false;

  async uploadFile(event: any) {
    const url = `manage/audiofile/upload/?project_id=${this.$route.params.id}`;

    try {
      const { data } = await axios.post(url, event.data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
        onUploadProgress(e) {
          event.onProgress(e, event.file);
        },
      });
      this.audioDataSource.items.push(data);
    } catch (e) {
      throw e;
    }
  }

  async start(item) {
    let url = `manage/audiofile/recognize`;
    const { data } = await axios.post(url, [item.id]);
    for (item of data) {
      let index = this.audioDataSource.items.findIndex((a) => a.id == item.id);
      if (index >= 0) {
        Vue.set(this.audioDataSource.items, index, item);
      }
    }
  }
  async get(item) {
    let url = `manage/audiofile/ttsdata`;
    const { data } = await axios.post(url, [item.id]);
    for (item of data) {
      let index = this.audioDataSource.items.findIndex((a) => a.id == item.id);
      if (index >= 0) {
        Vue.set(this.audioDataSource.items, index, item);
      }
    }
  }
  show(item) {
    this.currentItem = item;
    for (let chunk of this.currentItem.transcribtion.response.chunks) {
      chunk.startTime = this.parseAudioPosition(
        chunk.alternatives[0]?.words[0]?.startTime
      );
    }
    this.currentItem.transcribtion.response.chunks =
      this.currentItem.transcribtion.response.chunks.sort(function (a, b) {
        return a.startTime - b.startTime;
      });
    this.currentTime = 0;
    this.audioTextDialogVisible = true;
  }

  seekAudio(startTime, endTime) {
    (this.$refs.audio as any).currentTime = this.parseAudioPosition(startTime);
  }
  timeUpdate(event) {
    this.currentTime = event.srcElement.currentTime;
  }

  parseAudioPosition(position) {
    return position ? parseFloat(position.replace("s", "")) : null;
  }

  created() {
    this.dataSource.get();
  }
}
